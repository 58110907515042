import { Box, Button, Divider, Grid, IconButton } from '@mui/material';
import React, { Fragment } from 'react';
import MainCard from '../../ui-components/cards/MainCards';
import { useDashboardCircle } from './dasboardCircleFunction';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import StopCircle from '@mui/icons-material/StopCircle';

import VerticalChartTwo from './VerticalChartTwo';
import VoterComp from './VoterComp';



const DashboardCircleComp = (props) => {
    const { isStart, timeLeftIndex, timeLeft,
        handleStart, listData,
        handlePause, isPause, infoChart, handleChartOpen, chartOpen, voterOpen, handleVoterOpen,
        listComment, handleCloseVote, showComment
    } = useDashboardCircle();




    return (
        <Fragment>
            { listData.map((post, index) => {
                return (
                    <Fragment key={index}>
                        <MainCard title={post.subject} key={index}>
                            <Grid container spacing={20} justifyContent="space-between" alignItems="center">
                                <Grid item xs={6}>
                                    {timeLeftIndex === index && isStart &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                fontSize: 32,
                                            }}
                                        >
                                            <div>{Math.floor((timeLeft / 60) / 60)}</div>
                                            <div>:</div>
                                            <div>{Math.floor(timeLeft / 60) > 60 ? Math.floor(timeLeft / 60) - 60 : Math.floor(timeLeft / 60)}</div>
                                            <div>:</div>
                                            <div>{Math.floor(timeLeft - (Math.floor(timeLeft / 60) * 60))}</div>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" justifyContent="flex-end">
                                        {!isStart && post.is_active &&
                                            <Button onClick={() => handleStart(post, index)} variant="contained" color="primary">Mulai</Button>
                                        }
                                        {isStart && timeLeftIndex === index &&
                                            <>
                                                <IconButton aria-label="fingerprint" color={isPause ? "primary" : 'warning'} onClick={handlePause}>
                                                    {isPause ?
                                                        <PlayCircleIcon fontSize="large" />
                                                        :
                                                        <PauseCircleFilledIcon fontSize="large" />
                                                    }
                                                </IconButton>
                                                <IconButton aria-label="fingerprint" color={isPause ? "primary" : 'error'} onClick={handleCloseVote}>
                                                    <StopCircle fontSize="large" />
                                                </IconButton>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container flexDirection="column">
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <div style={{ fontWeight: 'bold' }}>Pilihan</div>
                                </Grid>
                                {post.list_answers.map((answer, indexAnswer) => {
                                    return (
                                        <Grid key={indexAnswer} item xs={6} sx={{ mb: 1 }}>
                                            - &nbsp;{answer.answer}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            {infoChart && timeLeftIndex === index &&
                                <Grid container spacing={20} justifyContent="space-between">
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <div className="count">
                                                    <div className='circle-count'>{infoChart && infoChart.unvote && infoChart.unvote.length}</div>
                                                </div>
                                            </Grid>
                                            <Grid item sx={{ marginTop: 5 }}>
                                                <div>Daftar Belum Melakukan Vote</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <div className="count second-count">
                                                    <div className='circle-count'>{infoChart && infoChart.voter && infoChart.voter.length}</div>
                                                </div>
                                            </Grid>
                                            <Grid item sx={{ marginTop: 5 }}>
                                                <div style={{ textAlign: 'center' }}>Daftar Sudah Melakukan Vote</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            {/* {infoChart && timeLeftIndex === index && */}
                            <Fragment>
                                <Grid container>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button onClick={() => handleChartOpen(index)} variant="contained" color="primary">Tampilkan Chart</Button>
                                        </Box>
                                    </Grid>
                                    {post.chartOpen &&
                                        <VerticalChartTwo
                                            vote_id={post.id}
                                            is_inactive={true}
                                        />
                                    }
                                </Grid>
                            </Fragment>
                            {/* } */}
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            {/* { timeLeftIndex === index && */}
                            <Fragment>
                                <Grid container>
                                    { post.tampilkan_voter &&
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button onClick={() => handleVoterOpen(index)} variant="contained" color="primary">Tampilkan Pemilih</Button>
                                            </Box>
                                        </Grid>
                                    }
                                    {post.voterOpen && post.is_open &&
                                        < VoterComp
                                                voters={infoChart && infoChart.voter ? infoChart.voter : []}
                                                unvoters={infoChart && infoChart.unvote ? infoChart.unvote : []}
                                            />
                                    }
                                    { post.voterOpen && !post.is_open &&
                                        < VoterComp
                                            voters={post.participant && post.participant.voter ? post.participant.voter : []}
                                            unvoters={post.participant && post.participant.unvote ? post.participant.unvote : []}
                                        />
                                    }
                                    </Grid>
                            </Fragment>
                            {/* } */}
                        </MainCard>
                        <div style={{ marginBottom: 20 }} />
                    </Fragment>
                )
            })}
            { showComment &&
                <div className='scroll-text-container'>
                    <div className="scroll-text-content">
                        {listComment.map((post, index) => {
                            return (
                                <div key={index} className='scroll-text'>
                                    <div className='flex-text'>
                                        <div className="initial-frame" style={{ backgroundColor: post.color }}>
                                            {post.initial}
                                        </div>
                                        <div>
                                            <div className="voter-name-scroll">{post.nama}</div>
                                            <div className="voter-perusahaan-scroll"> {post.perusahaan}</div>
                                            <div className="voter-description-scroll"> Baru melakukan voting</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </Fragment>
    )
}
export default DashboardCircleComp;