import { useCallback, useEffect, useState, useRef } from "react";
import api from "../../store/api";
import configApp from "../../store/configApp";
import { useGlobalFunction } from "../../store/globalFunction";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export function useDashboardCircle(props) {
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue,
    } = useGlobalFunction()
    const [listData, setListData] = useState([]);
    const [timeLeft, setTimeLeft] = useState(0);
    const [isStart, setIsStart] = useState(false);
    const [timeLeftIndex, setTimeLeftIndex] = useState(null);
    const [isPause, setIsPause] = useState(false);
    const [infoChart, setInfoChart] = useState(null);
    const [chartOpen, setChartOpen] = useState(false);
    const [voterOpen, setVoterOpen] = useState(false);
    const [listComment, setListComment] = useState([]);    
    const [selectedVote, setSelectedVote] = useState(null);
    const [showComment, setShowComment] = useState(false);
    const color = [ '#ffeb3b', '#8e24aa', '#388e3c', '#d81b60', '#03a9f4']


    const handleStart = async (post, index) => {
        setTimeLeftIndex(index);
        setTimeLeft(post.minutes * 60 || 30);
        setIsStart(true);
        setIsLoading(true);
        setSelectedVote(post);        
        try {
            const res = await api.put(`${configApp.endPoint.assignVoteToSesi}${post.id}/`).then(res => res.data);
            if (res) {
                setInfoChart(res.results.participant);
            }
            setIsLoading(true);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, configApp.timeOutValue)
        }
    }

    const handleStartOpen = (post, index) => {
        setTimeLeftIndex(index);
        if (post.is_pause){
            setTimeLeft(post.pause_second);
            setIsPause(true);
        }else{
            setTimeLeft(post.time_left);
        }
        setIsStart(true);
        setSelectedVote(post);
    }


    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            let params = {
                'query': 'current_date'
            }
            const res = await api.get(`${configApp.endPoint.vote}`, { params: params }).then(res => res.data);
            let tmp = [];            
            if (res) {
                res.results.map(post => {                    
                    let obj = { ...post }
                    obj['chartOpen'] = false;
                    obj['votertOpen'] = false;   
                    obj['time_left'] = post.time_left;             
                    tmp.push(obj)
                    setTimeLeft(post.time_left)
                    return true;
                })
            }
            setListData(tmp);
            tmp.map((post, index) => {
                if (post.is_open){                    
                    // setTimeLeftIndex(index);
                    // setTimeLeft(post.minutes * 60 || 30);
                    // setIsStart(true);                    
                    // setSelectedVote(post);
                    // obj['time_left'] = post.time_left
                    // handleStart(post, index);
                    if (post.time_left > 0){
                        handleStartOpen(post, index);
                    }
                }
                return true;
            })
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, configApp.timeOutValue)
        }
    }, [setIsLoading, setAlertValue])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleUpdateListVote = (id, result, ) => {
        let array = [...listData];
        let indexArray = array.findIndex(x => x.id === id);
        if (indexArray > -1){
            array[indexArray] = result;
        }
        setListData(array);
        setTimeLeftIndex(null);
        setIsStart(false);
    }

    const handleCloseVote = useCallback(async() => {        
        setIsLoading(true);
        if (selectedVote){
            try{
                const res = await api.put(`${configApp.endPoint.voteClose}${selectedVote.id}/`).then(res => res.data);                
                if (res){
                    handleUpdateListVote(res.results.id, res.results)
                    setAlertValue(alertValue => ({...alertValue, text : `${selectedVote.subject} telah ditutup`, color : 'success'}))
                    setTimeout(() => {
                        setAlertValue(alertValue => ({...alertValue, show: false}));
                    }, configApp.timeOutValue);
                }
                setIsLoading(false);
            }catch(error){            
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, configApp.timeOutValue)
            }
        }
    },[selectedVote, handleUpdateListVote])   

    useEffect(() => {
        if (!isPause) {
            if (!timeLeft) {
                setIsStart(false);
                handleCloseVote();

                return;
            }
            
            const intervalId = setInterval(() => {
                if (timeLeft > 0){
                    setTimeLeft(timeLeft - 1);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timeLeft, isPause]);

    const handlePause = async() => {
        setIsPause(!isPause);
        try{            
            let tmpPause;
            if (isPause){
                tmpPause = false;
            }else{
                tmpPause = true;
            }
            let tmp = {
                time_left : timeLeft,
                isPause : tmpPause
            }
            let formData = new FormData();
            formData.append('formData', JSON.stringify(tmp))
            const res = await api.put(`${configApp.endPoint.votePause}${selectedVote.id}/`, formData).then(res => res.data);
            if (res){                
                return;
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, configApp.timeOutValue)
        }
    }

    const handleChartOpen = (index) => {
        let array = [...listData];
        array[index] = {...array[index], chartOpen : !array[index].chartOpen}
        setListData(array);
    }


    const handleVoterOpen = (index) => {
        let array = [...listData];
        array[index] = {...array[index], voterOpen : !array[index].voterOpen}
        setListData(array);
        // setVoterOpen(!voterOpen);
    }

    

    // websocket 
    const ws = useRef(null);
    useEffect(() => {
        ws.current = new W3CWebSocket(configApp.apiBaseUrl.wsServer + 'new_voter/');
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => console.log("ws closed");

        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        ws.current.onmessage = e => {            
            const message = JSON.parse(e.data);
            if (message && message.text && message.text.data){
                const tmpObj = message.text.data;
                setListComment([...listComment, {
                    nama: tmpObj.nama,
                    perusahaan: tmpObj.perusahaan,
                    initial: tmpObj.initial,
                    color : color[listComment.length % 4]
                }]);
                setShowComment(true);
                setTimeout(() => {
                    setShowComment(false);
                }, 10000)
            }            
            if (message && message.text && message.text.voters){
                setInfoChart(message.text.voters)
            }
        };
    }, [listComment, color]);

    

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue, listData, setListData,
        handleStart, timeLeft, isStart, timeLeftIndex, handlePause, isPause, infoChart,
        handleChartOpen, chartOpen, handleVoterOpen, voterOpen, listComment, 
        color, handleCloseVote, showComment
    }
}