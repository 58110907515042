import { useState, useCallback, useEffect, useRef } from "react";
import api from "../../store/api";
import configApp from "../../store/configApp";
import { useGlobalFunction } from "../../store/globalFunction";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export function useDashboard(props){
    const vote_id = props && props.vote_id ? props.vote_id : sessionStorage.getItem('vote_id') ? sessionStorage.getItem('vote_id') : null;
    const is_inactive = props && props.is_inactive ? props.is_inactive : false;
    const { setIsLoading, setAlertValue, isLoading,  alertValue, handleCloseAlert} = useGlobalFunction();    
    const [labelsVote, setLabelsVote] = useState(['Tanpa ISR (Murni) aa', 'Tidak Sesuai Peruntukannya bb', 'Tidak Sesuai Parameter Teknis cc']);
    const [subjectVote, setSubjectVote] = useState(null)
    const [listVoter, setListVoter] = useState([]);
    
    const [dataVote, setDataVote] = useState({
        labelsVote,
        datasets: [
            {
                label: '',
                data: [20, 50, 30],
                backgroundColor: ['rgb(255, 99, 132)', 'rgb(53, 162, 235)', 'rgba(94, 196, 125, 1)'],                
            },            
        ],        
    });

    const [optionsVote, setOptionVote] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
        legend: {
            display: false
        },
        tooltips: {
            enabled : false
        },        
    });

    const fetchDataVote = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {}
            if (vote_id){
                params['vote_id'] = vote_id
            }
            if (!is_inactive){
                params['is_active'] = true;
            }
            const res = await api.get(`${configApp.endPoint.voteDashboard}`, {params : params}).then(res => res.data);            
            if (res){
                const copyArray = [...res.results]
                let label = copyArray.map(x => x.answer);                
                let tmp = copyArray.map(x => x.qty);   
                if (res.voter && res.voter.length > 20){
                    setOptionVote(optionsVote => ({...optionsVote,
                        scales : {
                            y: {
                                min: 0,
                                stepSize: res.voter.length / 4,
                                max: res.voter.length
                            }
                        }
                    }))
                }             
                
                const obj = {
                    labels : label,
                    datasets : [
                        {
                            label : '',
                            data : tmp,
                            backgroundColor : ['rgb(255, 99, 132)', 'rgb(53, 162, 235)', 'rgba(94, 196, 125, 1)']
                        }
                    ],                    
                }                
                setDataVote(obj)                      
                setLabelsVote(label);
                setSubjectVote(res.vote_subject)
                if (res.tampilkan_voter){
                    setListVoter(res.voter)
                }
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            const { data } = error.response;
            let msg = "Unable to post data"
            if (data && data.message){
                msg = data.message
            }
            setAlertValue(alertValue => ({...alertValue, show : true, text : msg, color : 'error'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, configApp.timeOutValue)
        }
    },[setIsLoading, setAlertValue, vote_id, is_inactive])

    useEffect(() => {
        fetchDataVote();
    },[fetchDataVote])


    const fetchDataHadir = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${configApp.endPoint.daftarHadirDashboard}`).then(res => res.data);            
            if (res){
                setListDataHadir(res.results);                
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch data', color : 'error'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, configApp.timeOutValue)
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchDataHadir();
    },[fetchDataHadir])


    // websocket
    const roomState = {
        filledForm: false,
        messages: [],
        value: '',
        name: '',
        room: 'test',
    }    
    
    const [isPaused, setPause] = useState(false);
    const ws = useRef(null);

    useEffect(() => {
        ws.current = new W3CWebSocket(configApp.apiBaseUrl.wsServer + roomState.room + '/');
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => console.log("ws closed");

        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            if (isPaused) return;
            const message = JSON.parse(e.data);            
            if (message && message.text === 'vote'){
                fetchDataVote()
            }else if (message && message.text === 'daftar_hadir'){
                fetchDataHadir()
            }
        };
    }, [isPaused, fetchDataVote, fetchDataHadir]);


    // daftar hadir
    const [listDataHadir, setListDataHadir] = useState([]);

    return {
        setIsLoading, setAlertValue, isLoading,
        dataVote, optionsVote, subjectVote, labelsVote, ws,
        listDataHadir, listVoter, alertValue, handleCloseAlert, setPause
    }
}