import React from 'react';
import { 
    Box, CssBaseline, AppBar, Toolbar,
    IconButton,  Container, Grid, Button,
    MenuItem, Menu, 
} from '@mui/material';


// asset
import Dashboard from '@mui/icons-material/Dashboard';
import HistoryIcon from '@mui/icons-material/History';
import BgImage from '../../assets/images/bg-dashboard.jpeg'

import { Outlet, useNavigate } from 'react-router-dom';
import {   useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import configApp from '../../store/configApp';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    menuPaper: {
        borderRadius: 5
    }
}));

const boxStyle = {
    backgroundImage: `url(${BgImage})`,
    backgroundAttachment : 'fixed',
    height: 'auto',
    minHeight: '100vh',
    // backgroundPosition: 'center',
    backgroundSize : 'cover',
}

const MainLayout = () => {
    const theme = useTheme();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const mycookies = new Cookies();
    const myCookiesRes = mycookies.get(configApp.secondCookiesName)
    const userInfo = mycookies && mycookies.get(configApp.secondCookiesName) ? mycookies.get(configApp.secondCookiesName).user_info.nama : ''
    // const isAdmin = myCookiesRes && myCookiesRes.is_admin ? myCookiesRes.is_admin : false
    const navigate = useNavigate();



    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles()


    const logout = (e) => {
        // mycookies.remove(configApp.cookiesName, { path: '/' });
        mycookies.remove(configApp.secondCookiesName, { path: '/' });
		return navigate('/login');
    }

    return(
        <Box 
            sx={{ 
                height: window.innerHeight, 
                width: '100%', 
                display: 'flex', 
                background: '#f2f6fc',                 
            }}
            style={boxStyle}
        >
            <CssBaseline/>
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={2}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar variant="dense">
                    <Grid
                        container
                        // spacing={12}
                        alignItems="center"
                        // sx={{display: 'grid'}}
                        // direction={'row'}
                        // flexWrap="wrap"
                        // wrap="nowrap"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Grid 
                                container
                                alignItems="center"
                            >
                                {/* { window.screen.width > 480 && */}
                                    <Grid item>
                                        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                                            {/* <MenuIcon />*/}
                                            <a href="/"><img src={require('../../assets/images/logo_apjii_small.png')} alt="logo" style={{height: window.screen.width > 480 ? 80 : 30}}/></a>
                                        </IconButton>
                                    </Grid>
                                {/* } */}
                                { myCookiesRes && myCookiesRes.is_admin &&
                                    <Grid 
                                        item
                                        sx={{
                                            pl: 5
                                        }}
                                    >
                                        <Button onClick={() => navigate('/dashboard')}>
                                            <Dashboard sx={{marginRight: 1}}/>
                                            Dashboard
                                        </Button>                                    
                                    </Grid>
                                }
                                <Grid 
                                    item
                                    sx={{
                                        pl: 0
                                    }}
                                >
                                    <Button onClick={() => navigate('/vote-history')}>
                                        <HistoryIcon sx={{marginRight: 1}}/>
                                        {myCookiesRes && myCookiesRes.is_admin ? 'Vote List' : 'Vote History' }
                                    </Button>                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button 
                                color="primary" 
                                variant="contained"                                 
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                {userInfo &&  userInfo}
                            </Button>
                            <Menu
                                sx={{
                                    mt:6,
                                    borderRadius: .2
                                }}
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                classes={{ paper: classes.menuPaper }}
                            >
                                <MenuItem onClick={logout}>Logout</MenuItem>                                
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>         
            </AppBar>
            <Container 
                maxWidth="xl"
                // sx={{
                //     background: '#fff',
                // }}
            >
                <CssBaseline/>
                <Toolbar/>
                <Box 
                    component="main" 
                    sx={{                        
                        mt: 8,                        
                    }}
                >                    
                    <Outlet />
                </Box>  
            </Container>
        
        </Box>
    )
}

export default MainLayout;