import React, { Fragment } from "react";
import { 
    Grid, 
    Collapse,    
    Card,
    CardHeader,
    CardContent,
    IconButton,     
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Button,    
} from "@mui/material";

import { gridSpacing } from '../../store/constant';
import MainCard from "../../ui-components/cards/MainCards";
import { useHistoryVote } from "./historyVoteFunction";
import Cookies from 'universal-cookie';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import configApp from "../../store/configApp";
import VerticalChart from "../Dashboard/VerticalChart";

const HistoryVoteMain = (props) => {
    const mycookies = new Cookies();
    const isAdmin = mycookies.get(configApp.secondCookiesName) && mycookies.get(configApp.secondCookiesName).is_admin ? true : false;
    const { listData, handleOpenList, handleSetToDashboard} = useHistoryVote({isAdmin: isAdmin});
    return(
        <Fragment>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <MainCard title="History Vote">
                        {  listData.map((post, index) => {
                            return(
                                <Card 
                                    key={index}  
                                    sx={{
                                        border: '1px solid #cecece',
                                        borderRadius: 1,
                                    }}                                  
                                >
                                    <CardHeader
                                        title={post.subject}
                                        action={
                                            <IconButton aria-label="settings" onClick={() => handleOpenList(index)} sx={{color: 'white'}}>
                                            { post.isOpen ? <ExpandLess/> : <ExpandMore/>}
                                            </IconButton>
                                        }
                                        sx={{
                                            backgroundColor: '#8914fe',
                                            color: '#fff'
                                        }}
                                        titleTypographyProps={{ color: 'white' }} 
                                    >
                                    </CardHeader>
                                    <Collapse in={post.isOpen} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            { isAdmin &&
                                                <Fragment>
                                                    <VerticalChart vote_id={post.id} is_inactive={true}/>
                                                    <Grid container justifyContent="flex-end" sx={{mt: 2}}>
                                                        <Button variant="contained" color="primary" onClick={() => handleSetToDashboard(post)}>Jadikan sebagai dashboard utama</Button>
                                                    </Grid>
                                                </Fragment>
                                            }
                                            { !isAdmin &&                                            
                                                <Fragment>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>Jawaban</TableCell>
                                                                    <TableCell>:</TableCell>
                                                                    <TableCell>{post.the_answer && post.the_answer.answer}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Waktu Vote</TableCell>
                                                                    <TableCell>:</TableCell>
                                                                    <TableCell>{post.the_answer && post.the_answer.answered_at}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Fragment>
                                            }
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            )
                        })}
                    </MainCard>
                </Grid>
            </Grid>
        </Fragment>
    )
}
export default HistoryVoteMain;