import React from 'react';
import MinimalLayout from '../layout/MinimalLayout/MinimalLayout';
import AuthLogin from '../views/Authentication/AuthLogin';

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        }        
    ]
};

export default AuthenticationRoutes;