import React, { Fragment  } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {  Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Loader from '../../ui-components/Loader';
import { useDashboard } from './dashboardFunction';

import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOffIcon from '@mui/icons-material/PersonOff';


const DaftarHadirChart = () => {
    const { isLoading, listDataHadir} = useDashboard()
    
    return(
        <Fragment>
            { isLoading && <Loader/> }
            { listDataHadir.map((post, index) => {
                return(
                    <Card variant="outlined" key={index}>
                        <CardContent>
                            <div
                                style={{
                                    fontSize: 16
                                }}
                            >Daftar Hadir</div>
                            <div
                                style={{
                                    color: '#7d7c7c',
                                    marginBottom: 20,
                                }}
                            >{post.event} {post.nama_sesi}</div>
                            <div
                                style={{
                                    color: '#7d7c7c',
                                    textAlign: 'right'
                                }}
                            >{post.total_absen} / {post.total_peserta}</div>
                            <LinearProgress variant="determinate" value={post.persentase} />
                            <div style={{marginTop: 20}}/>
                            { post.list_peserta.map((peserta, pesertaIndex) => {
                                return(
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} key={pesertaIndex}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: peserta.is_hadir ? '#c8e4fb' : '#9ea9b1' }}>
                                                    {peserta.is_hadir ? <HowToRegIcon /> : <PersonOffIcon color="disabled" /> }
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={peserta.nama} secondary={peserta.perusahaan} />
                                        </ListItem>
                                    </List>
                                )
                            })}
                        </CardContent>
                    </Card>                    
                )
            })}
            
        </Fragment>
    )
}
export default DaftarHadirChart;