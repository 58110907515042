import { Grid } from '@mui/material';
import React, { Fragment, useEffect, useCallback } from 'react';
import {  useParams } from 'react-router';
import configApp from '../../store/configApp';
import axios from 'axios'

import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

const StoreTokenPage = (props) => {
    const {token} = useParams();
    const mycookies = new Cookies();
    const navigate = useNavigate();

    const storeToken = useCallback(async() => {        
        try{
            // test token 
            const headers = { 
                'Accept' : 'application/json', 
                'Content-Type' : 'application/json',
                'Authorization' : `Token ${token}`
            }
            const api = axios.create({
                baseURL : configApp.apiBaseUrl[process.env.NODE_ENV],
                headers: headers,    
                timeout : 50000
            });
            const res = await api.get(`${configApp.endPoint.getRole}`).then(res => res.data);
            if (res){
                let tmpcookie = {
                    'token' : token, 
                    user_info : res.nama,
                    is_admin: true,
                };
                mycookies.set(configApp.secondCookiesName, tmpcookie, { path: '/', maxAge: configApp.expiresSession })
                return navigate('/')
            }
            else
            {
                return navigate('/login')
            }
        }catch(error){
            return navigate('/login')
        }
    },[])

    useEffect(() => {
        storeToken();
    },[storeToken])
    

    return(
        <Fragment>
            <Grid container direction="column" justifyContent="flex-end" sx={{  width: '100%' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '70vh', backgroundColor: '#fff', borderRadius: 3 }}>
                        Please wait ....
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}
export default StoreTokenPage;