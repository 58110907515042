import { Fragment } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Grid } from "@mui/material";
import MainCard from "../../ui-components/cards/MainCards";
import { useDashboard } from "./dashboardFunction";
import AlertComp from "../../ui-components/AlertComp/AlertComp";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const VerticalChartTwo = (props) => {
    const my_vote_id = props && props.vote_id ? props.vote_id : null; 
    const is_inactive  = props && props.is_inactive ? props.is_inactive : false;
    const { 
        subjectVote, optionsVote ,dataVote, listVoter, alertValue, handleCloseAlert
    } = useDashboard({vote_id : my_vote_id, is_inactive : is_inactive});
    
    return(
        <Fragment>
            <AlertComp alertValue={alertValue} handleCloseAlert={handleCloseAlert}/>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <MainCard title={subjectVote && subjectVote}>
                        <Bar options={optionsVote} data={dataVote} />
                    </MainCard>
                </Grid>
            </Grid>
        </Fragment>
    )
}
export default VerticalChartTwo;