import React  from 'react';
import './App.css';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NavigationScroll from './layout/NavigationScroll';
import Routes from './routes/routes'
import { useSelector } from 'react-redux';
import theme from './themes';
import { SnackbarProvider } from 'notistack';



const App = () => {  
  const customization = useSelector((state) => state.customization);
  

  return (    
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme(customization)}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <NavigationScroll>
                <Routes />
            </NavigationScroll>
          </SnackbarProvider>
        </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
