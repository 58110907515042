import {     
    useState 
} from "react"
import api from "../../store/api";
import configApp from "../../store/configApp";
import { useGlobalFunction } from "../../store/globalFunction";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';


// import Cookies from 'universal-cookie';
// import configApp from "../../store/configApp";

export function useAuth(){
    // const mycookies = new Cookies();
    const [cookies, setCookie] = useCookies([configApp.secondCookiesName]);
    const navigate = useNavigate();
    const { 
        isLoading, setIsLoading, alertValue, handleCloseAlert, setAlertValue
    } = useGlobalFunction();
    const [formData, setFormData] = useState({
        password : {
            name : 'password',
            label : 'Password',
            type: 'password',
            required : true,
            showError : false,
            value : ''
        }
    })

    const handleChange = (e) => {
        const { name, value } = e.target;        
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter"){
            handleSubmit();
        }
    }

    // useEffect(() => {
    //     const mycookies = new Cookies();    
    //     mycookies.remove(configApp.secondCookiesName, { path: '/' });
    // },[])

    const handleSubmit = async() => {        
        setIsLoading(true);
        try{
            if (formData.password.value === ''){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Masukan password', color : 'error'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, configApp.timeOutValue);
                setIsLoading(false);
                return;
            }
            let tmp = {
                'auth_kode' : formData.password.value
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${configApp.endPoint.login}`, newForm).then(res => res.data);
            if (res){
                let tmpcookie = {
                    'token' : res.token, 
                    user_info : res.user_info,
                    is_admin : false,
                };
			    setCookie(configApp.secondCookiesName, tmpcookie, { path: '/', maxAge: configApp.expiresSession });
                return navigate('/');    
            }            
            setIsLoading(false);
        }catch(error){
            const { data } = error.response;
            let msg = "Unable to post data";
            setIsLoading(false);
            let  status  = error && error.response && error.response.status ? error.response.status : '';

            if (data && data.msg){
                msg = data.msg
            }

            if (status === 401){
                const mycookies = new Cookies();
                mycookies.remove(configApp.secondCookiesName, { path: '/' });
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'error'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, configApp.timeOutValue);
        }
    }

    
    return {
        formData, handleChange,handleSubmit, alertValue, isLoading, handleCloseAlert, cookies,handleKeyDown
    }
}