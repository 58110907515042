import { useCallback, useEffect, useState, useRef } from "react";
import api from "../../store/api";
import configApp from "../../store/configApp";
import { useGlobalFunction } from "../../store/globalFunction";
import { w3cwebsocket as W3CWebSocket } from "websocket";
// import { w3cwebsocket as W3CWebSocket } from "websocket";

export function useHome(props){
    const isAnswer = props && props.isAnswer ? props.inAnswer : false;
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue,
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    // const mycookies = new Cookies();
    // const userInfo = mycookies && mycookies.get(configApp.secondCookiesName) ? mycookies.get(configApp.secondCookiesName).user_info : null

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = { 
                'is_answer' : isAnswer, 
                'is_active' : true,               
            }
            const res = await api.get(`${configApp.endPoint.vote}`, { params : params}).then(res => res.data);
            let tmp = []
            if (res){
                res.results.map(post => {
                    if (!post.is_answer){
                        let obj = {...post};
                        obj['isOpen'] = false;
                        obj['userAnswer'] = '';                    
                        tmp.push(obj);
                    }
                return true;
                })
            }
            setListData(tmp)
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);
            let  status  = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401){
                return window.location.href = "/login"
            }else{
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch Data', color : 'error'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));
                }, configApp.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue, isAnswer])

    

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleOpenList = (index) => {
        let array = [...listData];
        array[index] = {...array[index], isOpen: !array[index].isOpen}
        setListData(array);
    }

    const handleSubmit  = async(index) => {
        setIsLoading(true);
        try{
            let array = [...listData];
            if (array[index].userAnswer === ''){
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Pilih Salah satu jawaban', color : 'error'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));
                }, configApp.timeOutValue);
                setIsLoading(false);
                return;
            }
            let tmpForm = array[index];
            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(tmpForm));

            const res = await api.put(`${configApp.endPoint.postVote}${array[index].id}/`, newForm).then(res => res.data);
            if (res){
                fetchData()
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let  status  = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401){
                return window.location.href = "/login"
            }else{
                const { data } = error.response;
                let msg = "Unable to post data"
                if (data && data.message){
                    msg = data.message
                }
                setAlertValue(alertValue => ({...alertValue, show : true, text : msg, color : 'error'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));
                }, configApp.timeOutValue)
            }
        }
    }

    const handleChangeAnswer = (index, e) => {
        const { value } = e.target;
        let array = [...listData];
        array[index] = {...array[index], userAnswer : value}
        setListData(array);
    }

    // ======= websocket ======
    const ws = useRef(null);
    useEffect(() => {
        ws.current = new W3CWebSocket(configApp.apiBaseUrl.wsServer + 'test/');
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => console.log("ws closed");

        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        if (!ws.current) return;
        ws.current.onmessage = e => {            
            const message = JSON.parse(e.data);            
            if (message && message.text === 'vote'){
                fetchData()
            }
        };
    }, [fetchData]);

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue, listData, handleOpenList,
        handleSubmit, handleChangeAnswer
    }
}