export default   {
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        // development: "http://127.0.0.1:8000",
        // production: "http://127.0.0.1:8000", 
        // wsServer : "ws://127.0.0.1:8000/ws/",
        // development: "https://backendsite.apjii.or.id",
        // production: "https://backendsite.apjii.or.id", 
        // wsServer : "wss://backendsite.apjii.or.id/ws/",
        development: "https://apjiiabsen.myappscenter.com",
        production: "https://apjiiabsen.myappscenter.com", 
        wsServer : "wss://apjiiabsen.myappscenter.com/ws/",
        // wsServer : "ws://103.179.56.148:8001/ws/",
        // development: "http://10.0.0.43:8000",
        // production: "http://10.0.0.43:8000",        
    },    
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__ftthUser",
      ACCESS_TOKEN: "__ftthToken",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",      
      PERUSAHAAN : '_perusahaanInfo',
    },
    // cookiesName : '__eventAPJII25e__',
    secondCookiesName : '__eventAPJII222e__',
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 10000,
    endPoint : {
      // auth
        login : `/auth/api/login-vote-web/`,
        getRole : '/auth/api/get-all-role',

        vote : `/vote/api/v1/vote/`,
        votePause : `/vote/api/v1/vote-pause/`,
        assignVoteToSesi : `/vote/api/v1/assign-vote-tosesi/`,
        postVote : `/vote/api/v1/post-vote/`,
        voteDashboard : `/vote/api/v1/vote-dashboard/`,
        daftarHadirDashboard : `/munas/api/v1/daftar-hadir-dashboard/`,
        voteClose : `/vote/api/v1/close-vote/`,
    }
}