import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {     
    Alert,
    Snackbar,
} from '@mui/material';

const AlertComp = (props) => {
    const {
        alertValue,
        handleCloseAlert
    } = props
    return (
        <Fragment>
            <Snackbar open={alertValue.show} onClose={handleCloseAlert} anchorOrigin={{ vertical : 'bottom', horizontal: 'right' }}>
                <Alert severity={alertValue.color} variant="filled" onClose={handleCloseAlert} sx={{mb: 2, width: '100%'}}>{alertValue.text}</Alert>
            </Snackbar>
        </Fragment>
    )
}

AlertComp.propTypes = {
    alertValue : PropTypes.object.isRequired,
    handleCloseAlert : PropTypes.func.isRequired
}

export default AlertComp;