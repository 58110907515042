import { Grid } from "@mui/material";
import { Fragment } from "react"
import MainCard from "../../ui-components/cards/MainCards";
import DaftarHadirChart from "./DaftarHadirChart";
import DashboardCircleComp from "./DashboardCircleComp";
import VerticalChart from "./VerticalChart";


const DashboardPage = (props) => {
    return(
        <Fragment>
            <MainCard
                sx={{
                    minHeight: '78vh'
                }}
            >
                <Grid
                    justifyContent="space-between"
                    container
                    spacing={3}
                    alignItems="flex-start"                    
                >
                    
                    {/* <Grid item md={12} lg={8} xl={8}>
                    </Grid> */}
                    <Grid item md={12} lg={8} xl={8}>
                        {/* <VerticalChart/> */}
                        <DashboardCircleComp/>
                    </Grid>
                
                    <Grid item md={12} lg={4} xl={4}>
                        <DaftarHadirChart/>
                    </Grid>
                </Grid>
                <div style={{marginTop: 20}}/>
                
            </MainCard>
        </Fragment>
    )
}
export default DashboardPage;