import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const VoterComp = (props) => {
    const { voters, unvoters } = props;
    return(
        <Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <h2 style={{marginLeft: 15}}>Belum Melakukan Voting</h2>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        { unvoters.map((post, index) => {
                            return(
                                <Fragment key={index}>
                                    <ListItem sx={{mt: -2}}>
                                        <ListItemText primary={post.kontak_name} secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >                                                    
                                                    {post.perusahaan}
                                                </Typography>
                                            </Fragment>
                                        } />
                                    </ListItem>
                                </Fragment>   
                            )
                        })}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <h2 style={{marginLeft: 15}}>Sudah Melakukan Voting</h2>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        { voters.map((post, index) => {
                            return(
                                <Fragment key={index}>
                                    <ListItem sx={{mt: -2}}>
                                        <ListItemText primary={post.kontak_name} secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >                                                    
                                                    {post.perusahaan}
                                                </Typography>
                                                <div style={{borderBottom: '1px solid #cecece', paddingBottom: 15}}>
                                                    Jawaban : { post.answer }
                                                </div>
                                            </Fragment>
                                        } />
                                    </ListItem>
                                </Fragment>   
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
        </Fragment>
    )
}

VoterComp.propTypes = {
    voters : PropTypes.array.isRequired,
    unvoters : PropTypes.array.isRequired,
}

export default VoterComp;