import React from 'react';
import MainLayout from "../layout/MainLayout/MainLayout"
import DashboardPage from '../views/Dashboard/DashboardPage';
import HistoryVoteMain from '../views/HistoryVote/HistoryVoteMain';
import HomePage from '../views/Home/HomePage';
import StoreTokenPage from '../views/Home/StoreTokenPage';


const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element : <HomePage/>
        },
        {
            path: '/dashboard',
            element : <DashboardPage/>
        },
        {
            path: '/vote-history',
            element : <HistoryVoteMain/>
        },
        {
            path: '/store-token/:token',
            element : <StoreTokenPage/>
        }
    ]
}
export default MainRoutes