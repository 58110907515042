import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { forwardRef } from "react";
import PropTypes from 'prop-types';

const MainCard = forwardRef(
    (
        {
            content = true,
            border = true,
            children,
            contentSX = {},
            contentClass = '',
            title,
            sx = {},
            ...others
        }, 
        ref
    ) => {
        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: '#dee2e6',
                    borderRadius : 2,                  
                    ...sx
                }}
            >
                { title && <CardHeader title={title} />}
                { title && <Divider/>}
                { content &&
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                }
                {!content && children}
            </Card>
        )
    }
)

MainCard.propTypes = {
    border: PropTypes.bool,    
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,    
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;