import React, { Fragment, useState } from 'react';
import { Grid, Stack, Typography, useMediaQuery,
    FormControl, InputLabel, OutlinedInput, FormHelperText,
    Box, Button,
    InputAdornment,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BgImage from '../../assets/images/login-bg2.jpg'
import AuthCardWrapper from './AuthCardWrapper';
import { Link } from 'react-router-dom';
import { useAuth } from './loginFunction';
import AnimateButton from '../../ui-components/extends/AnimateButton';
import AlertComp from '../../ui-components/AlertComp/AlertComp';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const styles = {
    paperContainer: {
        height: '100%',
        backgroundImage: `url(${BgImage})`
    }
};

const AuthLogin = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { formData, handleChange,
        alertValue, handleCloseAlert, handleSubmit, isLoading, handleKeyDown
    } = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Fragment>
            <AlertComp
                alertValue={alertValue}
                handleCloseAlert={handleCloseAlert}
            />
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }} style={styles.paperContainer}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <img src={require('../../assets/images/logo_apjii.png')} alt="logo"/>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>                                                    
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Hi, Welcome Back
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="16px"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        Enter your credentials to continue
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            fullWidth                            
                                            sx={{ ...theme.typography.customInput }}
                                        >
                                            <InputLabel htmlFor="outlined-adornment-email-login">Insert Your Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-email-login"
                                                type={showPassword ? 'text' : 'password'}
                                                value={formData.password.value}
                                                name="password"
                                                onChange={handleChange}
                                                label="Password"
                                                onKeyDown={handleKeyDown}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {formData.password.showError && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                                    Wajib diisi
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        <Box sx={{ mt: 2 }}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    disabled={isLoading}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleSubmit}
                                                >
                                                    Sign in
                                                </Button>
                                            </AnimateButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}
export default AuthLogin;