
import React, { Fragment, } from 'react';
import {
    Divider, Grid,
    Collapse,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    ButtonGroup,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@mui/material';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-components/cards/MainCards';
import Loader from '../../ui-components/Loader';
import { useHome } from './homeFunction';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AlertComp from '../../ui-components/AlertComp/AlertComp';
import Cookies from 'universal-cookie';
import configApp from '../../store/configApp';
import DashboardPage from '../Dashboard/DashboardPage';


const HomePage = (props) => {
    const {
        isLoading, listData, handleOpenList, handleSubmit, handleChangeAnswer, alertValue, handleCloseAlert
    } = useHome();
    const mycookies = new Cookies();
    const mycookiesRes = mycookies.get(configApp.secondCookiesName);


    // const getFrameValueOneExec = useCallback(async() => {
    //     const childname = "popup";    
    //     let loading = true
    //     var childwinTwo;
    //     childwinTwo = window.open(targetUrl, childname, 'height=2px, width=2px'); 
    //     let msg={pName : "Bob", pAge: "35"};
    //     setTimeout(() => {
    //         childwinTwo.postMessage(msg, targetUrl)// childwin is the targetWindow           
    //         loading = false;
    //     }, 500)  

    //     setTimeout(() => {
    //         if (!loading){
    //             childwinTwo.close();            
    //         }
    //     }, 1000)  
    // },[]);

    // // useEffect(() => {
    // //     getFrameValueOneExec()
    // // },[getFrameValueOneExec])




    // const onMessageReceivedFromIframe = React.useCallback(event => {
    //     if (event.origin === targetUrl){
    //         setMyToken(event.data);                
    //     }
    // },[]);

    // useEffect(() => {
    //     window.addEventListener("message", onMessageReceivedFromIframe);
    //     return () =>
    //       window.removeEventListener("message", onMessageReceivedFromIframe);
    // }, [onMessageReceivedFromIframe]);

    return (
        <Fragment>
            { isLoading && <Loader />}
            <AlertComp
                alertValue={alertValue}
                handleCloseAlert={handleCloseAlert}
            />
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    {mycookiesRes && mycookiesRes.is_admin &&
                        <DashboardPage />
                    }
                    {mycookiesRes && !mycookiesRes.is_admin &&
                        <MainCard title="Voting">

                            {listData.length === 0 &&
                                <div style={{
                                    backgroundColor: '#cce5ff',
                                    paddingTop: 30,
                                    paddingBottom: 30,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    borderRadius: 8
                                }}>
                                    <div style={{ fontSize: 16 }}>
                                        Saat ini belum ada subject yang perlu di vote
                                    </div>
                                </div>
                            }

                            {listData.map((post, index) => {
                                return (
                                    <Card
                                        key={index}
                                        sx={{
                                            border: '1px solid #cecece',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <CardHeader
                                            title={post.subject}
                                            action={
                                                <IconButton aria-label="settings" onClick={() => handleOpenList(index)} sx={{ color: 'white' }}>
                                                    {post.isOpen ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            }
                                            sx={{
                                                backgroundColor: '#8914fe',
                                                color: '#fff'
                                            }}
                                            titleTypographyProps={{ color: 'white' }}
                                        >
                                        </CardHeader>
                                        <Collapse in={post.isOpen} timeout="auto" unmountOnExit>
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item lg={4}>

                                                        {post.is_answer ?
                                                            <Fragment>
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell>Jawaban</TableCell>
                                                                                <TableCell>:</TableCell>
                                                                                <TableCell>{post.the_answer && post.the_answer.answer}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell>Waktu Vote</TableCell>
                                                                                <TableCell>:</TableCell>
                                                                                <TableCell>{post.the_answer && post.the_answer.answered_at}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <Grid item lg="auto" xs={12}>
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            value={post.userAnswer}
                                                                            name="radio-buttons-group"
                                                                            onChange={(e) => handleChangeAnswer(index, e)}
                                                                        >
                                                                            {post.list_answers.map((answer, answerIndex) => {
                                                                                return (
                                                                                    <FormControlLabel 
                                                                                        key={answerIndex} 
                                                                                        value={answer.id} 
                                                                                        disabled={!post.is_open}
                                                                                        control={<Radio />} label={answer.answer} 
                                                                                    />
                                                                                )
                                                                            })}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                                
                                                                <Grid container spacing={gridSpacing} sx={{ mt: 2, pl: 3 }}>
                                                                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                                                        <LoadingButton
                                                                            size="small"
                                                                            color="primary"
                                                                            onClick={() => handleSubmit(index)}
                                                                            loading={isLoading}
                                                                            loadingPosition="start"
                                                                            startIcon={<SaveIcon />}
                                                                            variant="contained"
                                                                            disabled={!post.is_open}
                                                                        >
                                                                            Submit
                                                                        </LoadingButton>
                                                                    </ButtonGroup>
                                                                </Grid>
                                                            </Fragment>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                )
                            })}
                        </MainCard>
                    }
                </Grid>
            </Grid>
        </Fragment>
    )
}
export default HomePage;