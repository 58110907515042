import { Fragment } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Grid, List, ListItem,  ListItemText, Typography} from "@mui/material";
import MainCard from "../../ui-components/cards/MainCards";
import { useDashboard } from "./dashboardFunction";
import AlertComp from "../../ui-components/AlertComp/AlertComp";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const VerticalChart = (props) => {
    const my_vote_id = props && props.vote_id ? props.vote_id : null; 
    const is_inactive  = props && props.is_inactive ? props.is_inactive : false;
    const { 
        subjectVote, optionsVote ,dataVote, listVoter, alertValue, handleCloseAlert
    } = useDashboard({vote_id : my_vote_id, is_inactive : is_inactive});
    
    return(
        <Fragment>
            <AlertComp alertValue={alertValue} handleCloseAlert={handleCloseAlert}/>
            <Grid container spacing={0}>
                <Grid item xs={listVoter.length === 0 ? 12: 8}>
                    <MainCard title={subjectVote && subjectVote}>
                        <Bar options={optionsVote} data={dataVote} />
                    </MainCard>
                </Grid>
                { listVoter.length > 0 &&
                    <Grid item xs={4}>
                        <h2 style={{marginLeft: 15}}>Daftar Pemilih</h2>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            { listVoter.map((post, index) => {
                                return(
                                    <Fragment key={index}>
                                        <ListItem sx={{mt: -2}}>
                                            <ListItemText primary={post.nama} secondary={
                                                <Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >                                                    
                                                        {post.perusahaan}
                                                    </Typography>
                                                    <div style={{borderBottom: '1px solid #cecece', paddingBottom: 15}}>
                                                        Jawaban : { post.jawaban }
                                                    </div>
                                                </Fragment>
                                            } />
                                        </ListItem>
                                    </Fragment>   
                                )
                            })}
                        </List>
                    </Grid>
                }
            </Grid>
        </Fragment>
    )
}
export default VerticalChart;