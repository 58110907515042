import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import api from "../../store/api";
import configApp from "../../store/configApp";
import { useGlobalFunction } from "../../store/globalFunction"

export function useHistoryVote(props){
    const isAdmin = props && props.isAdmin ? props.isAdmin : false
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue,
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const navigate = useNavigate();

    const fetchData = useCallback(async() => {
        setIsLoading(true)
        try{
            let params =  {}
            if (!isAdmin){
                params['is_answer'] = true;
            }            
            const res = await api.get(`${configApp.endPoint.vote}`, { params : params}).then(res => res.data);
            let tmp = []
            if (res){
                res.results.map(post => {
                    if (post.is_answer){
                        let obj = {...post};
                        obj['isOpen'] = false;
                        obj['userAnswer'] = '';                    
                        tmp.push(obj);
                    }
                    return true;
                })
            }
            setListData(tmp)
            setIsLoading(false);    
        }catch(error){
            setIsLoading(false);
            let  status  = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401){
                return window.location.href = "/login"
            }else{
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch Data', color : 'error'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));
                }, configApp.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue, isAdmin])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleOpenList = (index) => {
        let array = [...listData];
        array[index] = {...array[index], isOpen: !array[index].isOpen}
        setListData(array);
    }

    const handleSetToDashboard = (post) => {
        sessionStorage.setItem('vote_id', post.id);
        navigate('/')
    }

    return{
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue,
        listData, handleOpenList, handleSetToDashboard
    }
}