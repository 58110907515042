import { useState } from 'react';


export function  useGlobalFunction(){
    const [isLoading, setIsLoading] = useState(false);
    
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : '',
        color : 'error'
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        params : null
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
    }

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],
        cabang_id : '',
        cabang_obj : [],
        tipe_harga_id : '',
        tipe_harga_obj : [],
        lastPage : 0,
        tipe_kontak : '',
        status_active : '',
        start_date : null,
        end_date: null,
        lokasi_id : '',
        lokasi_tujuan : '',
        lokasi_asal: '',
        perusahaan : 1,
        perusahaanObj : [],
        myIndex: 0,
        outlet : '',
        outletObj : [],
        orderBy : '',
        orderType : 'asc',
        expired_deposite : '',
        expired_deposite_obj : [
            {value : '', label : '-- Tampilkan Semua --'},
            {value : '1', label : 'Sudah Expired'},
            {value : '2', label : 'Expired bulan ini'},
        ],
        serviceObj : [],
        subServiceObj : [],
        serviceValue : '',
        subServiceValue : '',     
	});
    const [limitValue, setLimitValue] = useState({
        limit : 10,
        obj : [
            {value : 10, label :  10},
            {value : 25, label :  25},
            {value : 50, label :  50},
            {value : 100, label : 100},
        ]
    });


    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        })
    }

    const caughtNotLogin = (code) => {
        let { response } = code
        if (response && response.status === 401){
            return window.location.href = "/#/login/"
        }
        return response;
    }
   
        
    // const getPermission = (permissionName) => {
    //     if (roles.is_superuser || roles.roles.includes(permissionName)){
    //         return true;
    //     }else{                        
    //         return false
    //     }
    // }


    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, limitValue, setLimitValue,
        caughtNotLogin
        // fetchAccount, 
        // fetchProdukSatuan, 
        // getPermission,
    }
}